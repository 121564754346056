$browser-context: 14; // Default

@function em($pixels, $context: $browser-context) {
  @return #{$pixels/$context}em;
}

@media screen and (max-width: 1899px) {
  .page-nav {
    padding: 200px 0 30px 0;
  }
  //container
  .container {
    //padding: 0 253px;
    padding: 0 265px;
  }
  .item-view {
    width: calc(100% + 265px);
    margin-left: -265px;
    padding-left: 265px;
  }
  .header-wrap {
    margin: 0 0 0 -265px;
  }

  .form-sended {
    margin: 0 0 0 -265px;
  }
  .production-info-wrap {
    margin: 0 -265px 0 0;
  }
  .production-view {
    padding-right: 265px;
  }
  .about-info-bg,
  .materials-bg {
    span {
      right: -265px;
    }
  }

  .equipment-info {
    .bg-text {
      right: -265px;
    }
  }
  .main-logo {
    width: 265px;
    padding: 0 15px 0 29px;
  }

  .header-list {
    width: calc(100% - 265px);
  }
  .extra-container {
    //padding: 0 109px 0 0;
    padding: 0 71px 0 0;
  }

  .contacts-wrap {
    /*386 = 70 + 316*/
    //margin: 0 -362px 0 -62px;
    margin: 0 -336px 0 -62px;
  }

  .principles-bg,
  .prices-bg {
    right: -336px;
  }
  //container

  .contacts {
    width: 37.5%;
    padding: 61px 69px 25px 64px;
  }
  .map-wrap {
    width: calc(100% - 37.5%);
  }
  .main-btn {
    width: 305px;
    height: 92px;
    padding: 10px 40px;
    font-size: 17px;
    span {
      &:after {
        width: 67px;
      }
    }
    &.left-line {
      span {
        padding: 0 0 0 75px;
      }
      &:hover {
        span {
          &:after {
            width: 107px;
            left: -40px;
          }
        }
      }
    }
    &.right-line {
      span {
        padding: 0 75px 0 0;
        &:after {
          right: 0;
        }
      }
      &:hover {
        span {
          &:after {
            width: 107px;
            right: -40px;
          }
        }
      }
    }
  }

  .right-sidebar {
    width: 263px;
  }
  .catalog-btn, .modal-close {
    min-height: 125px;
    padding: 35px 15px 44px 45px;
    font-size: 35px;
  }
  .go-back {
    min-height: 225px;
    padding: 30px 15px 45px 47px;
    font-size: 35px;
    line-height: 1.1;
  }
  .go-back span {
    display: block;
    padding: 0 0 13px 0;
    position: relative;
  }

  .header-wrap {
    padding: 45px 0;
  }
  .main-logo a {
    max-width: 100px;
  }
  .main-logo a.fixed,
  .main-logo a.open {
    top: 54px;
    left: 29px;
  }
  .header-item .item-icon {
    width: 37px;
    height: 37px;
  }
  .header-item ul {
    left: 37px;
  }
  .header-item .item-text {
    padding: 0 0 0 11px;
    font-size: 15px;
  }
  .header-item ul li {
    padding: 3px 10px 3px 11px;
  }
  .header-item ul li a {
    font-size: 15px;
  }
  .header-item {
    margin: 0 57px 0 0;
  }

  .error-type p {
    font-size: 247px;
  }
  .error-type h2 {
    font-size: 201px;
  }
  .page-error p {
    padding: 44px 0 0 0;
    max-width: 310px;
    font-size: 15px;
  }
  .page-error-btn {
    padding: 49px 0 0 0;
  }
  .page-error-btn a {
    width: 337px;
    height: 77px;
  }
  .not-found-wrap {
    padding: 44px 0 128px 0;
  }

  .contacts h4,
  .page-error h1,
  .goods-list-title h2,
  .category-title h3,
  .section-title h3,
  .equipment-renew-text h4,
  .info-title h1 {
    font-size: 54px;
  }
  .info-title {
    padding: 0 0 40px 0;
  }
  .info-text p {
    margin: 0 0 20px 0;
    font-size: 15px;
  }
  .info-content {
    padding: 41px 0 100px 0;
  }
  .contacts-info {
    padding: 14px 0;
  }
  .contacts-info p {
    font-size: 16px;
  }

  .contacts-info a {
    margin: 9px 0 0 0;
    font-size: 20px;
  }
  .social-links {
    padding: 24px 0;
  }
  .social-links a {
    font-size: 13px;
  }
  .social-links a.linked-in {
    width: 35px;
    height: 35px;
  }

  .contacts-bottom {
    padding: 147px 0 15px 0;
  }
  .site-rights p,
  .made-in a span {
    font-size: 11px;
  }
  .made-in a i {
    font-size: 12px;
  }
  /*.certificate-img img {
    max-width: 39px;
  }*/

  .item-slider-wrap {
    width: 42%;
  }
  .product-data {
    width: calc(100% - 42%);
  }
  .product-info {
    padding: 0 0 0 66px;
  }
  .product-name h1 {
    font-size: 37px;
  }
  .product-name p {
    padding: 6px 0 0 0;
    font-size: 22px;
  }
  .product-name {
    padding: 0 0 16px 0;
  }
  .product-list li {
    padding: 10px 0;
    font-size: 16px;
  }
  .product-list {
    padding: 0 0 26px 0;
  }

  .product-price p {
    font-size: 24px;
  }
  .product-price {
    padding: 30px 0 0 0;
  }

  .product-order {
    max-width: 322px;
    padding: 31px 0 0 0;
  }
  .product-order p {
    padding: 0 0 22px 0;
    font-size: 20px;
  }
  .product-order button {
    width: 322px;
    height: 77px;
  }
  .item-slider-wrap, .product-data {
    padding-bottom: 90px;
  }
  .section-prev, .section-next {
    width: 57px;
    height: 57px;
  }
  .section-pagination .pagination-current {
    padding: 0 4px 0 0;
    font-size: 38px;
  }
  .section-pagination {
    font-size: 17px;
  }
  .chosen-product {
    padding: 0 0 58px 0;
  }

  .goods-list-wrap {
    padding: 22px 0 0 0;
  }

  .goods-item a {
    padding: 18px 24px;
  }
  .goods-item a span {
    padding: 0 0 4px 0;
    max-width: 133px;
    font-size: 19px;
  }
  .load-more {
    padding: 66px 0 0 0;
  }
  .load-more button {
    width: 200px;
    padding: 26px 56px 23px 26px;
    font-size: 17px;
  }
  .load-more button i {
    font-size: 14px;
    top: 40%;
    right: 27px;
  }
  .goods-pagination .pagination-wrap {
    max-width: 365px;
  }
  .goods-pagination {
    padding: 30px 0;
  }
  .production-notice p {
    max-width: 849px;
    font-size: 37px;
  }
  .production-notice {
    padding: 46px 0 21px 0;
  }
  .map-title h3 {
    padding: 0 0 12px 0;
    font-size: 37px;
  }
  .goods-list {
    padding: 36px 0 28px 0;
  }
  .map-title {
    padding-bottom: 105px;
    margin-bottom: -26px;
  }
  .map-title:after {
    height: 106px;
    left: 34px;
  }
  .left-sidebar {
    width: 200px;
  }
  .catalog-toolbar-title h1 {
    font-size: 27px;
  }
  .catalog-toolbar-wrap {
    padding: 0 18px 0 41px;
  }
  .catalog-toolbar-title {
    padding: 0 0 31px 0;
  }
  .filter-item h3 {
    padding: 0 18px 0 0;
    font-size: 14px;
  }
  .filter-item ol li label span {
    padding: 0 0 0 27px;
    font-size: 13px;
  }
  .filter-item ol {
    padding: 9px 0;
  }
  .filter-item ol li {
    padding: 7px 0;
  }
  .catalog-toolbar {
    padding: 163px 0 20px 0;
  }

  .category-view {
    margin: -56px 0 0 0;
  }
  .category-title p {
    //font-size: 149px;
    font-size: 100px;
  }
  .catalog-category {
    min-height: calc(100vh - 71px);
  }
  .category-info {
    //width: 44%;
    padding: 53px 0 160px 0;
  }
  .category-img {
    //width: calc(100% - 44%);
  }
  .category-view-control {
    padding: 0 0 88px 0;
  }
  .category-img-wrap {
    right: -103px;
    left: -104px;
  }

  .send-order {
    min-height: 225px;
    padding: 46px 15px 45px 40px;
  }
  .send-order span.send-btn {
    font-size: 35px;
  }
  .send-order span.request {
    font-size: 15px;
  }

  .main-section-img span {
    top: -127px;
    left: -4px;
    right: -181px;
  }

  .main-section-text {
    padding: 92px 0;
  }
  .main-title p {
    padding: 0 0 27px 0;
    font-size: 22px;
  }
  .main-title h1 {
    padding: 0 0 32px 0;
    font-size: 69px;
  }
  .see-products p {
    font-size: 22px;
  }
  .see-products {
    padding: 120px 0 56px 0;
  }
  .see-products a.main-btn {
    width: 320px;
    height: 80px;
  }

  .page-nav ol li a {
    padding: 0 10px 0 48px;
    font-size: 12px;
  }
  .page-nav ol li a:before {
    width: 38px;
  }
  .about-info {
    padding: 57px 20px 0 0;
  }
  .about-info .section-list {
    padding: 40px 0;
  }
  .section-list ol li {
    padding: 0 0 0 30px;
    font-size: 15px;
  }
  .about-info .section-list ol li {
    margin: 0 0 34px 0;
  }
  .about-info-bg span {
    bottom: -49px;
  }
  .our-mission-text h4 {
    font-size: 29px;
  }
  .our-mission-text {
    padding: 127px 34px 75px 61px;
  }
  .products-wrap {
    padding: 53px 0 0 0;
  }
  .processing-wrap {
    padding: 56px 0 86px 0;
  }
  .section-title p {
    padding: 15px 0 0 0;
    font-size: 26px;
  }
  .processing-list {
    padding: 35px 0 15px 0;
  }

  .processing-item-text {
    padding: 32px 25px 28px 33px;
  }
  .processing-item-text i {
    font-size: 47px;
  }
  .processing-item-text p {
    padding: 29px 0 0 0;
    font-size: 49px;
  }
  .processing-item-text p span {
    font-size: 19px;
  }
  .min-eq-number p {
    font-size: 260px;
    line-height: 192px;
  }
  .min-eq-text p {
    font-size: 36px;
  }
  .production-info-wrap {
    padding: 58px 0 38px 0;
  }
  .production-info .section-list {
    padding: 40px 0 0 0;
  }
  .production-info .section-list ol {
    max-width: 572px;
  }
  .production-info .section-list ol li {
    margin: 0 0 39px 0;
  }
  .production-info {
    padding: 0 30px 81px 0;
  }
  .production-info-slider {
    padding: 12px 0 81px 0;
  }
  .production-company {
    margin: 0 0 0 -50px;
    padding: 0;
  }
  .equipment-info {
    padding: 57px 0 19px 0;
  }
  .equipment-text .section-list {
    padding: 46px 0 0 0;
  }
  .equipment-text .section-list ol li {
    margin: 0 0 33px 0;
  }
  .equipment-info .bg-text {
    font-size: 189px;
    line-height: 219px;
    max-width: 580px;
  }

  .equipment-renew-text {
    padding: 35px 0 43px 0;
  }
  .equipment-renew-text p {
    padding: 12px 0 0 0;
    font-size: 19px;
  }
  .equipment-renew-img {
    width: 480px;
    height: 227px;
  }
  .principles-wrap {
    padding: 58px 0 66px 0;
  }
  .principles-list {
    padding: 44px 0;
  }
  .principles-item {
    padding: 34px 34px;
  }
  .principles-icon {
    height: 43px;
    margin: 0 0 38px 0;
    font-size: 42px;
  }
  .principles-text h4 {
    font-size: 28px;
  }
  .principles-text p {
    font-size: 14px;
  }

  .materials-wrap {
    padding: 58px 25px 38px 0;
  }
  .materials-list {
    padding: 22px 0;
  }
  .materials-item .materials-item-eq {
    width: 156px;
    padding: 14px 40px 15px 15px;
    font-size: 60px;
  }
  .materials-item p {
    font-size: 15px;
    line-height: 1.5;
  }
  .materials-item {
    padding: 25px 0 24px 128px;
  }
  .materials-list ul li {
    margin: 0 0 35px 0;
  }
  .materials-advantage {
    padding: 90px 0 0 25px;
  }
  .materials-advantage p {
    font-size: 42px;
  }
  .prices-data {
    padding: 58px 0 37px 0;
  }
  .fields-title h3 {
    max-width: 830px;
    font-size: 32px;
  }
  .section-form {
    padding: 49px 0 0 0;
  }
  .fields-wrap {
    padding: 33px 29px 4px 29px;
  }
  .field-item label {
    font-size: 16px;
  }
  .fields-title {
    padding: 0 15px 27px 15px;
  }
  .field-item label input,
  .field-item label textarea,
  .field-item label select {
    padding: 0 0 20px 0;
  }
  .field-item {
    margin: 0;
  }
  .upload-file {
    padding: 26px 0 0 0;
  }
  .upload-file p {
    padding: 5px 32px 5px 0;
    font-size: 16px;
  }
  .file-field span {
    font-size: 14px;
  }
  .file-field {
    min-width: 151px;
    height: 51px;
    padding: 9px 54px 5px 21px;
  }
  .file-field i {
    right: 20px;
  }
  .form-submit button {
    height: 76px;
    font-size: 25px;
  }
  .form-submit button span {
    min-width: 202px;
  }
  .form-agreement {
    padding: 16px 0 0 0;
  }
  .form-agreement label span {
    //padding: 5px 0 5px 32px;
    font-size: 13px;
  }
  .ready-for p {
    padding: 0 0 0 17px;
    font-size: 23px;
  }
  .success-title h3 {
    font-size: 47px;
  }
  .success-info {
    padding: 0 0 84px 15px;
  }
  .success-title p {
    padding: 23px 0 0 0;
    font-size: 31px;
  }
  .success-back .main-btn {
    width: 372px;
    height: 77px;
  }
  .success-message p {
    padding: 0 0 17px 0;
    font-size: 23px;
  }
  .success-message a {
    max-width: 136px;
  }
  .success-message {
    padding: 73px 0 0 0;
  }
  .success-back {
    padding: 57px 0 0 0;
  }
  .success-img {
    max-width: 360px;
    right: 54%;
  }

  .category-img-wrap a {
    padding: 8px;
    left: 34%;
    top: 44%;
  }
  .category-img-wrap a span {
    width: 103px;
    height: 103px;
  }
  .category-img-wrap a span:before {
    top: -7px;
    left: -7px;
    right: -7px;
    bottom: -7px;
  }
  .category-img-wrap a span i {
    padding: 0 0 0 4px;
  }
}

//@media screen and (max-width: 1659px) {
  @media screen and (max-width: 1599px) {
    .page-nav {
      padding: 181px 0 30px 0;
    }
  //container
  .container {
    padding: 0 237px;
  }
  .item-view {
    width: calc(100% + 237px);
    margin-left: -237px;
    padding-left: 237px;
  }
  .header-wrap {
    margin: 0 0 0 -237px;
  }

  .form-sended {
    margin: 0 0 0 -237px;
  }
  .production-info-wrap {
    margin: 0 -237px 0 0;
  }
  .production-view {
    padding-right: 237px;
  }
  .about-info-bg,
  .materials-bg {
    span {
      right: -237px;
    }
  }

  .equipment-info {
    .bg-text {
      right: -237px;
    }
  }
  .main-logo {
    width: 237px;
    padding: 0 15px 0 36px;
  }

  .header-list {
    width: calc(100% - 237px);
  }

  .extra-container {
    padding: 0 66px 0 0;
  }

  .contacts-wrap {
    /*386 = 70 + 316*/
    margin: 0 -303px 0 -59px;
  }

  .principles-bg,
  .prices-bg {
    right: -303px;
  }
  //container

  .main-btn {
    padding: 10px 30px;
    font-size: 16px;
    span {
      &:after {
        width: 58px;
      }
    }
    &.left-line {
      span {
        padding: 0 0 0 65px;
      }
      &:hover {
        span {
          &:after {
            width: 88px;
            left: -30px;
          }
        }
      }
    }
    &.right-line {
      span {
        padding: 0 65px 0 0;
        &:after {
          right: 0;
        }
      }
      &:hover {
        span {
          &:after {
            width: 88px;
            right: -30px;
          }
        }
      }
    }
  }

  .main-logo a {
    max-width: 93px;
  }
    .main-logo a.fixed,
    .main-logo a.open {
      top: 48px;
      left: 36px;
    }
  .right-sidebar {
    width: 240px;
  }
  .catalog-btn, .modal-close {
    min-height: 111px;
    padding: 30px 15px 38px 40px;
    font-size: 30px;
  }
  .catalog-btn span:after, .modal-close span:after, .go-back span:after, .send-order span.send-btn:after {
    width: 25px;
  }
  .header-item .item-icon i {
    font-size: 12px;
  }
  .header-wrap {
    padding: 38px 0;
  }
  .header-item {
    margin: 0 50px 0 0;
  }

  .error-type p {
    font-size: 220px;
  }
  .not-found-wrap {
    padding: 37px 0 115px 0;
  }
  .error-type h2 {
    font-size: 186px;
  }
  .contacts h4,
  .page-error h1,
  .goods-list-title h2,
  .category-title h3,
  .section-title h3,
  .equipment-renew-text h4,
  .info-title h1 {
    font-size: 48px;
  }
    .info-title {
      padding: 0 0 25px 0;
    }
  .page-error p {
    padding: 35px 0 0 0;
  }
  .page-error-btn {
    padding: 43px 0 0 0;
  }
  .page-error-btn a {
    width: 315px;
    height: 69px;
  }
  .contacts-info {
    padding: 9px 0;
  }
  .chosen-product {
    margin: 19px 0 0 0;
    padding: 0 0 53px 0;
  }
  .item-slider-wrap {
    width: 43.5%;
  }
  .product-data {
    width: calc(100% - 43.5%);
  }
  .item-slider-wrap, .product-data {
    padding-bottom: 82px;
  }
  .product-info {
    padding: 0 0 0 58px;
  }
  .product-name h1 {
    font-size: 34px;
  }
  .product-name p {
    padding: 5px 0 0 0;
    font-size: 20px;
  }
  .product-list li {
    padding: 9px 0;
    font-size: 14px;
  }
  .product-list {
    padding: 0 0 22px 0;
  }
  .product-price p {
    font-size: 22px;
  }
  .product-order p {
    padding: 0 0 16px 0;
    font-size: 18px;
  }
  .product-order button {
    width: 290px;
    height: 69px;
  }
  .product-order {
    max-width: 290px;
    padding: 35px 0 0 0;
  }
  .section-prev, .section-next {
    width: 49px;
    height: 49px;
  }
  .section-prev i, .section-next i {
    font-size: 11px;
  }
  .section-pagination .pagination-current {
    font-size: 32px;
  }
  .goods-list {
    padding: 27px 0 19px 0;
  }
  .goods-list-wrap {
    padding: 19px 0 0 0;
  }
  .goods-item a span {
    max-width: 126px;
    font-size: 16px;
    line-height: 1.5;
  }
  .goods-item a {
    padding: 16px 22px;
  }
  .load-more {
    padding: 59px 0 0 0;
  }
  .load-more button {
    width: 181px;
    padding: 23px 56px 23px 25px;
    font-size: 16px;
  }
  .goods-pagination .pagination-wrap {
    max-width: 330px;
  }
  .goods-pagination {
    padding: 26px 0;
  }
  .production-notice p {
    max-width: 750px;
    font-size: 33px;
  }
  .map-title h3 {
    padding: 0 0 13px 0;
    font-size: 33px;
  }
  .map-title {
    padding-bottom: 94px;
    margin-bottom: -24px;
  }
  .map-title:after {
    height: 95px;
    left: 28px;
  }
  .contacts {
    padding: 53px 54px 16px 58px;
  }
  .go-back {
    min-height: 203px;
    padding: 30px 15px 41px 41px;
    font-size: 30px;
  }

  .left-sidebar {
    width: 180px;
  }
  .catalog-toolbar-wrap {
    padding: 0 10px 0 35px;
  }
  .catalog-toolbar-title h1 {
    font-size: 24px;
  }
  .catalog-toolbar {
    padding: 147px 0 20px 0;
  }
  .filter-item h3 {
    padding: 0 15px 0 0;
    font-size: 12px;
  }
  .filter-item.open h3:after {
    -ms-transform: rotate(-90deg);
    transform: rotate(-90deg);
  }
  .filter-item h3:after {
    top: 1px;
    font-size: 7px;
  }
  .catalog-toolbar-title {
    padding: 0 0 27px 0;
  }
  .filter-item ol li label span {
    padding: 0 0 0 24px;
    //font-size: 12px;
  }
  .filter-item ol li label span:before {
    width: 12px;
    height: 12px;
  }
  .filter-item ol li label span:after {
    width: 10px;
    height: 10px;
  }
  .filter-item ol li {
    padding: 6px 0;
  }
  .category-title p {
    //font-size: 133px;
    font-size: 90px;
  }
  .category-view {
    margin: -49px 0 0 0;
  }
  .category-info {
    padding: 48px 0 155px 0;
  }
  .catalog-category {
    min-height: calc(100vh - 64px);
  }
  .category-view-control {
    padding: 0 0 77px 0;
  }
  .category-img-wrap {
    right: -97px;
    left: -104px;
  }
  .send-order span.request {
    font-size: 14px;
  }
  .send-order span.send-btn {
    font-size: 30px;
  }
  .send-order {
    min-height: 203px;
    padding: 41px 15px 42px 39px;
  }
  .main-section-text {
    padding: 84px 0;
  }
  .main-title p {
    font-size: 20px;
  }
  .main-title h1 {
    padding: 0 0 20px 0;
    font-size: 62px;
  }
  .see-products {
    padding: 114px 0 56px 0;
  }
  .see-products p {
    font-size: 20px;
  }
  .see-products a.main-btn {
    width: 293px;
    height: 70px;
  }
  .main-section-img span {
    top: -111px;
    left: -9px;
    right: -121px;
  }
  .main-section-text {
    padding: 84px 0 73px 0;
  }
  .about-info {
    padding: 52px 20px 0 0;
  }
  .about-info .section-list {
    padding: 35px 0 47px 0;
  }
  .section-list ol li {
    padding: 0 0 0 29px;
    font-size: 14px;
  }
  .about-info .section-list ol li {
    margin: 0 0 23px 0;
  }
  .our-mission-text h4 {
    font-size: 27px;
  }
  .our-mission-text {
    padding: 116px 34px 66px 56px;
  }
  .products-wrap {
    padding: 51px 0 0 0;
  }
  .processing-wrap {
    padding: 48px 0 86px 0;
  }
  .section-title p {
    padding: 12px 0 0 0;
    font-size: 24px;
  }
  .processing-list {
    padding: 31px 0 15px 0;
  }
  .processing-item-text i {
    font-size: 39px;
  }
  .processing-item-text {
    padding: 28px 25px 28px 33px;
  }
  .processing-item-text p {
    padding: 28px 0 0 0;
    font-size: 45px;
  }
  .processing-item-text p span {
    font-size: 17px;
    padding: 10px 0 0 0;
  }
  .min-eq-number p {
    font-size: 230px;
    line-height: 170px;
  }
  .min-eq-text p {
    font-size: 33px;
  }
  .min-eq {
    padding: 21px 19px 21px 24px;
  }
  .production-info-wrap {
    padding: 44px 0 38px 0;
  }
  .production-info .section-list {
    padding: 34px 0 0 0;
  }
  .production-info .section-list ol li {
    margin: 0 0 30px 0;
  }
  .production-info {
    padding: 0 15px 70px 0;
  }
  .production-info-slider {
    padding: 12px 0 70px 0;
  }
  .equipment-info {
    padding: 49px 0 14px 0;
  }

  .equipment-text .section-list {
    padding: 41px 0 0 0;
  }
  .equipment-text .section-list ol li {
    margin: 0 0 29px 0;
  }
  .equipment-renew-text p {
    padding: 10px 0 0 0;
    font-size: 17px;
  }
  .equipment-renew-text {
    padding: 34px 0 38px 0;
  }
  .equipment-renew-img {
    width: 429px;
    height: 205px;
  }
  .principles-wrap {
    padding: 53px 0 75px 0;
  }
  .principles-list {
    padding: 39px 0;
  }
  .principles-text h4 {
    font-size: 25px;
  }
  .principles-icon {
    margin: 0 0 32px 0;
    font-size: 38px;
  }
  .principles-text p {
    font-size: 13px;
  }
  .principles-item {
    padding: 30px 20px 30px 39px;
  }
  .materials-list {
    padding: 17px 0;
  }
  .materials-item .materials-item-eq {
    width: 140px;
    padding: 14px 33px 15px 15px;
    font-size: 50px;
  }
  .materials-item {
    padding: 20px 0 24px 114px;
  }
  .materials-item p {
    font-size: 14px;
  }
  .materials-list ul li {
    margin: 0 0 30px 0;
  }
  .materials-advantage p {
    font-size: 38px;
  }
  .materials-advantage {
    padding: 80px 0 0 25px;
  }
  .materials-wrap {
    padding: 58px 25px 32px 0;
  }
  .prices-data {
    padding: 53px 0 21px 0;
  }
  .section-form {
    padding: 44px 0 0 0;
  }
  .fields-wrap {
    padding: 28px 25px 26px 25px;
  }
  .fields-title h3 {
    max-width: 735px;
    font-size: 29px;
  }
  .field-item label,
  .upload-file p {
    font-size: 14px;
  }
  .fields-title {
    padding: 0 15px 25px 15px;
  }
  .field-item label input, .field-item label textarea, .field-item label select {
    padding: 0 0 17px 0;
  }
  .field-item {
    padding: 15px 15px 11px 15px;
  }
  .file-field {
    min-width: 136px;
    height: 44px;
    padding: 9px 45px 5px 21px;
  }
  .file-field i {
    font-size: 15px;
  }
  .field-item label textarea {
    height: 104px;
  }
  .form-submit button {
    height: 69px;
    font-size: 23px;
  }
  .form-submit button span {
    min-width: 172px;
  }
  .form-agreement label span:before {
    width: 20px;
    height: 20px;
  }
    .form-agreement label span {
      width: 20px;
      height: 20px;
    }
  .form-agreement label span:after {
    top: 6px;
    left: 5px;
  }
  .page-nav ol li a {
    padding: 0 10px 0 45px;
  }
  .page-nav ol li a:before {
    width: 35px;
  }
  .page-nav ol li {
    padding: 14% 0;
  }
  .ready-for {
    padding: 15px 0 14px 5px;
  }
  .ready-for p {
    font-size: 21px;
  }
    .success-title h3 {
      font-size: 42px;
    }
    .success-title p {
      padding: 18px 0 0 0;
      font-size: 27px;
    }
    .success-message p {
      padding: 0 0 12px 0;
      font-size: 20px;
    }
    .success-message a {
      max-width: 119px;
    }
    .success-message {
      padding: 68px 0 0 0;
    }
    .success-back {
      padding: 50px 0 0 0;
    }
    .success-back .main-btn {
      width: 336px;
      height: 69px;
    }
    .success-info {
      padding: 0 0 84px 10px;
    }
    .success-img {
      max-width: 326px;
      right: 54%;
    }
    .success-info {
      padding: 0 0 55px 10px;
    }

    .category-img-wrap a {
      padding: 8px;
      left: 35%;
      top: 42%;
    }
    .category-img-wrap a span i {
      font-size: 20px;
    }
}

@media screen and (max-width: 1439px) {
  .page-nav {
    padding: 140px 0 30px 0;
  }
  //container
  .container {
    padding: 0 180px;
  }
  .item-view {
    width: calc(100% + 180px);
    margin-left: -180px;
    padding-left: 180px;
  }
  .header-wrap {
    margin: 0 0 0 -180px;
  }

  .form-sended {
    margin: 0 0 0 -180px;
  }
  .production-info-wrap {
    margin: 0 -180px 0 0;
  }
  .production-view {
    padding-right: 180px;
  }
  .about-info-bg,
  .materials-bg {
    span {
      right: -180px;
    }
  }

  .equipment-info {
    .bg-text {
      right: -180px;
    }
  }
  .main-logo {
    width: 180px;
    padding: 0 15px 0 15px;
  }

  .header-list {
    width: calc(100% - 180px);
  }

  .extra-container {
    padding: 0 50px 0 0;
  }

  .contacts-wrap {
    /*386 = 70 + 316*/
    margin: 0 -260px 0 -46px;
  }

  .principles-bg,
  .prices-bg {
    right: -230px;
  }

  //container

  .header-wrap {
    padding: 27px 0;
  }
  .main-logo a {
    max-width: 75px;
  }
  .main-logo a.fixed,
  .main-logo a.open {
    top: 34px;
    left: 15px;
  }
  .header-item .item-icon {
    width: 30px;
    height: 30px;
  }
  .header-item ul {
    left: 30px;
  }
  .header-item .item-icon i {
    font-size: 10px;
  }
  .header-item {
    margin: 0 35px 0 0;
  }
  .header-item .item-text,
  .header-item ul li a {
    font-size: 13px;
  }

  .right-sidebar {
    width: 181px;
  }
  .catalog-btn, .modal-close {
    min-height: 85px;
    padding: 21px 15px 29px 30px;
    font-size: 25px;
  }
  .catalog-btn span, .modal-close span {
    margin: 0 0 0 11px;
  }
  .error-type p {
    font-size: 166px;
  }
  .error-type h2 {
    font-size: 131px;
    margin-top: -17.5%;
  }

  .contacts h4,
  .page-error h1,
  .goods-list-title h2,
  .category-title h3,
  .section-title h3,
  .equipment-renew-text h4,
  .info-title h1 {
    font-size: 37px;
  }
  .info-content {
    max-width: 760px;
  }
  .page-error p {
    padding: 26px 0 0 0;
    font-size: 14px;
  }
  .contacts-info p {
    font-size: 14px;
  }
  .contacts-info a {
    margin: 5px 0 0 0;
    font-size: 17px;
  }
  .social-links {
    padding: 15px 0;
  }
  .social-links a.linked-in {
    width: 26px;
    height: 26px;
  }
  .social-links a {
    font-size: 10px;
  }
  .contacts-bottom {
    padding: 62px 0 15px 0;
  }
  .product-info {
    padding: 0 0 0 48px;
  }
  .product-name h1 {
    font-size: 26px;
  }
  .product-name p {
    font-size: 15px;
  }
  .product-list li {
    padding: 6px 0;
    font-size: 13px;
  }
  .product-name {
    padding: 0 0 10px 0;
  }
  .product-list {
    padding: 0 0 18px 0;
  }
  .product-price p {
    font-size: 17px;
  }
  .product-price {
    padding: 21px 0 0 0;
  }
  .product-order p {
    padding: 0 0 14px 0;
    font-size: 14px;
  }
  .product-order button {
    width: 226px;
    height: 53px;
  }
  .product-order {
    max-width: 226px;
    padding: 21px 0 0 0;
  }
  .item-slider-wrap, .product-data {
    padding-bottom: 60px;
  }
  .section-prev, .section-next {
    width: 38px;
    height: 38px;
  }
  .section-pagination .pagination-current {
    font-size: 25px;
  }
  .chosen-product {
    padding: 0 0 37px 0;
  }
  .goods-list {
    padding: 21px 0 19px 0;
  }
  .goods-list-wrap {
    padding: 15px 0 0 0;
  }
  .goods-item a span {
    max-width: 104px;
    font-size: 13px;
    line-height: 1.3;
  }
  .goods-item a {
    padding: 15px 17px;
  }
  .load-more {
    padding: 45px 0 0 0;
  }
  .load-more button {
    width: 145px;
    padding: 16px 46px 16px 18px;
    font-size: 14px;
  }
  .load-more button i {
    font-size: 11px;
    top: 40%;
    right: 22px;
  }
  .goods-pagination .pagination-wrap {
    max-width: 255px;
  }
  .goods-pagination {
    padding: 21px 0;
  }
  .production-notice p {
    max-width: 575px;
    font-size: 25px;
  }
  .production-notice {
    padding: 31px 0 13px 0;
  }
  .map-title h3 {
    padding: 0 0 11px 0;
    font-size: 25px;
  }
  .map-title {
    padding-bottom: 73px;
    margin-bottom: -19px;
  }
  .map-title:after {
    height: 73px;
    left: 20px;
  }
  .contacts {
    padding: 41px 45px 16px 44px;
  }
  .go-back {
    min-height: 155px;
    padding: 22px 15px 31px 33px;
    font-size: 25px;
    line-height: 1;
  }
  .left-sidebar {
    width: 137px;
  }
  .catalog-toolbar-title h1 {
    font-size: 19px;
  }
  .catalog-toolbar {
    padding: 113px 0 15px 0;
  }
  .catalog-toolbar-wrap {
    padding: 0 10px 0 15px;
  }
  .filter-item h3 {
    padding: 0 15px 0 0;
    font-size: 12px;
  }
  .filter-item h3:after {
    top: 1px;
    font-size: 6px;
  }
  .catalog-toolbar-title {
    padding: 0 0 22px 0;
  }
  .filter-item ol li {
    padding: 3px 0;
  }
  .filter-item ol {
    padding: 6px 0;
  }
  .filter-item ol li label span {
    padding: 0 0 0 19px;
  }
  .filter-item {
    padding: 8px 0;
  }

  .category-view {
    margin: -33px 0 0 0;
  }
  .category-info {
    padding: 33px 0 115px 0;
  }
  .catalog-category {
    min-height: calc(100vh - 51px);
  }
  .category-view-control {
    padding: 0 0 55px 0;
  }
  .category-title p {
    padding: 5px 0 0 0;
    //font-size: 103px;
    font-size: 76px;
  }
  .category-info {
    width: 63%;
  }
  .category-img {
    width: calc(100% - 63%);
  }
  .category-img-wrap {
    right: -77px;
    left: -104px;
  }
  .send-order span.send-btn {
    font-size: 25px;
    padding: 0 0 7px 0;
  }
  .send-order {
    min-height: 155px;
    padding: 27px 15px 31px 30px;
  }
  .send-order span.request {
    font-size: 11px;
  }
  .main-section-text {
    padding: 66px 0 44px 0;
  }
  .main-title p {
    padding: 0 0 19px 0;
    font-size: 16px;
  }
  .main-title h1 {
    font-size: 47px;
  }
  .see-products p {
    font-size: 15px;
  }
  .see-products {
    padding: 83px 0 56px 0;
  }
  .see-products a.main-btn {
    width: 230px;
    height: 59px;
  }
  .about-info {
    padding: 40px 15px 0 0;
  }
  .about-info .section-list {
    padding: 25px 0 36px 0;
  }
  .section-list ol li:before {
    font-size: 11px;
  }
  .section-list ol li {
    padding: 0 0 0 23px;
    font-size: 13px;
  }
  .about-info .section-list ol li {
    margin: 0 0 17px 0;
  }
  .about-info-bg span {
    bottom: -35px;
  }
  .our-mission-text h4 {
    font-size: 20px;
  }
  .our-mission-text {
    padding: 91px 34px 47px 45px;
  }
  .products-wrap {
    padding: 40px 0 0 0;
  }
  .processing-wrap {
    padding: 40px 0 58px 0;
  }
  .section-title p {
    padding: 9px 0 0 0;
    font-size: 18px;
  }
  .processing-list {
    padding: 19px 0 3px 0;
  }
  .processing-item-text {
    padding: 20px 20px;
  }
  .processing-item-text i {
    font-size: 34px;
  }
  .processing-item-text p {
    padding: 18px 0 0 0;
    font-size: 24px;
  }
  .processing-item-text p span {
    font-size: 14px;
    padding: 7px 0 0 0;
  }
  .min-eq-text p {
    font-size: 24px;
  }
  .min-eq-number p {
    font-size: 165px;
    line-height: 122px;
  }
  .min-eq {
    padding: 20px 19px 18px 19px;
  }
  .production-info-wrap {
    padding: 41px 0 17px 0;
  }
  .production-info .section-list {
    padding: 26px 0 0 0;
  }
  .production-info .section-list ol li {
    margin: 0 0 15px 0;
  }
  .main-section-img span {
    top: -84px;
  }
  .pagination-bullet:after {
    font-size: 12px;
  }
  .pagination-bullet {
    margin: 0 3px;
  }
  .production-info {
    padding: 0 15px 58px 0;
  }
  .production-info-slider {
    padding: 12px 0 58px 0;
  }
  .equipment-info {
    padding: 42px 0 14px 0;
  }
  .equipment-text .section-list {
    padding: 31px 0 0 0;
  }
  .equipment-text .section-list ol li {
    margin: 0 0 20px 0;
  }
  .equipment-info .bg-text {
    font-size: 123px;
    line-height: 1;
    max-width: 370px;
  }
  .equipment-renew-text p {
    font-size: 13px;
  }
  .equipment-renew-text {
    padding: 24px 0 28px 0;
  }
  .equipment-renew-img {
    width: 330px;
    height: 156px;
  }
  .principles-wrap {
    padding: 41px 0 46px 0;
  }
  .principles-list {
    padding: 30px 0;
  }
  .principles-item {
    padding: 22px 20px 20px 28px;
  }
  .principles-icon {
    margin: 0 0 25px 0;
    font-size: 33px;
    height: 34px;
  }
  .principles-text h4 {
    font-size: 20px;
  }
  .principles-text p {
    font-size: 12px;
    padding: 7px 0 0 0;
  }
  .materials-wrap {
    padding: 41px 15px 32px 0;
  }
  .materials-item .materials-item-eq {
    width: 120px;
    padding: 14px 33px 15px 15px;
    font-size: 40px;
  }
  .materials-item p {
    font-size: 13px;
    br {
      display: none;
    }
  }
  .materials-item {
    padding: 13px 0 13px 95px;
  }
  .materials-list ul li {
    margin: 0 0 25px 0;
  }
  .materials-advantage p {
    font-size: 29px;
  }
  .materials-advantage {
    padding: 63px 0 0 15px;
  }
  .prices-data {
    padding: 35px 0 21px 0;
  }
  .section-form {
    padding: 33px 0 0 0;
  }
  .fields-title h3 {
    max-width: 600px;
    font-size: 22px;
  }
  .fields-wrap {
    padding: 25px 13px;
  }
  .fields-title {
    padding: 0 15px 11px 15px;
  }
  .field-item label input, .field-item label textarea, .field-item label select {
    padding: 0 0 12px 0;
  }
  .field-item {
    padding: 15px 15px 2px 15px;
  }
  .field-item label textarea {
    height: 82px;
  }
  .upload-file {
    padding: 13px 0 0 0;
  }
  .form-submit button {
    height: 52px;
    font-size: 19px;
  }
  .form-submit button span {
    min-width: 143px;
  }
  .page-nav ol li a {
    padding: 0 10px 0 31px;
    font-size: 11px;
  }
  .page-nav ol li a:before {
    width: 27px;
  }
  .page-nav ol li {
    padding: 10% 0;
  }
  .ready-for p {
    font-size: 14px;
  }
  .success-title h3 {
    font-size: 32px;
  }
  .success-title p {
    padding: 17px 0 0 0;
    font-size: 20px;
  }
  .success-message p {
    padding: 0 0 12px 0;
    font-size: 16px;
  }
  .success-message a {
    max-width: 92px;
  }
  .success-message {
    padding: 54px 0 0 0;
  }
  .success-back {
    padding: 39px 0 0 0;
  }
  .success-back .main-btn {
    width: 302px;
    height: 60px;
  }
  .success-img {
    max-width: 247px;
    right: 54%;
  }
  .success-info {
    padding: 0 0 45px 10px;
  }
  .category-img-wrap a span {
    width: 80px;
    height: 80px;
  }
  .category-img-wrap a span:before {
    top: -5px;
    left: -5px;
    right: -5px;
    bottom: -5px;
    border: 2px solid #ffffff;
  }
  .category-img-wrap a span i {
    font-size: 16px;
  }
  .category-img-wrap a {
    padding: 6px;
    left: 23%;
  }
}

@media screen and (max-width: 993px) {
  //container
  .container {
    padding: 0 15px;
  }
  /*.item-view {
    width: calc(100% + 15px + 15px);
    margin: 0 -15px;
    padding: 0 15px;
  }*/
  .item-view {
    width: 100%;
    margin: 0;
    padding: 0;
  }
  .header-wrap {
    margin: 0;
  }

  .form-sended {
    margin: 0 0 0 -15px;
  }
  .production-info-wrap {
    margin: 0;
  }
  .production-view {
    padding-right: 0;
  }
  .about-info-bg,
  .materials-bg {
    span {
      right: -15px;
    }
  }

  .equipment-info {
    .bg-text {
      right: -15px;
    }
  }
  .main-logo {
    width: 90px;
    padding: 0 15px 0 0;
  }

  .header-list {
    //width: calc(100% - 90px);
    width: calc(100% - 90px - 30px);
    padding-left: 15px;
    justify-content: flex-end;
  }
  .header-item {
    margin: 0;
  }
  .header-item.desktop-only {
    display: none;
  }
  .mobile-sidebar {
    display: block;
    &.mobile-filter {
      opacity: 1;
      pointer-events: auto;
    }
    &.mobile-menu {
      opacity: 1;
      pointer-events: auto;
    }
  }
  .left-sidebar {
    width: 100%;
    opacity: 0;
    pointer-events: none;
    transition: opacity .3s ease-in-out;
    &.open {
      opacity: 1;
      pointer-events: auto;
    }
  }
  .page-nav {
    padding: 75px 0 75px 0;
  }

  .page-nav ol li {
    padding: 20px 10px;
    text-align: center;
  }
  .page-nav ol li a {
    font-size: 27px;
  }
  .page-nav ol li a {

    display: inline-block;
    padding: 5px 0;
  }
  .page-nav ol li a:before {
    width: auto;
    top: auto;
    left: 0;
    right: 0;
    bottom: 0;
    transform: translateY(0);
  }

  .extra-container {
    padding: 0;
  }

  .contacts-wrap {
    /*386 = 70 + 316*/
    margin: 0 -15px;
  }

  .principles-bg,
  .prices-bg {
    right: -15px;
  }

  //container

  .main-logo a.fixed,
  .main-logo a.open {
    position: static;
  }

  body {
    padding-bottom: 65px;
  }
  .modal-init {
    .fields-wrap {
      padding: 25px 0;
    }
    .fields-title {
      padding: 0 0 11px 0;
    }
    .field-item {
      padding: 15px 0 2px 0;
    }
  }
  .modal-init .container {
    padding-bottom: 75px;
  }
  .right-sidebar {
    width: 100%;
    height: 65px;
    flex-direction: row;
    top: auto;
    right: 0;
    left: 0;
    bottom: 0;
  }
  .catalog-btn, .modal-close, .go-back {
    min-height: auto;
    width: 50%;
    align-items: center;
    padding: 15px;
    font-size: 25px;
    line-height: 1;
    span {
      br {
        display: none;
      }
    }
  }
  .send-order {
    min-height: auto;
    width: 50%;
    align-items: center;
    flex-direction: row;
    padding: 15px;
  }
  .go-back span {
    padding: 3px 0;
  }
  .send-order span.send-btn {
    width: auto;
    padding: 3px 0;
    font-size: 25px;
    line-height: 1;
  }
  .send-order span.request {
    display: none;
  }

  .category-img-wrap {
    right: 0;
    left: 0;
  }
  .category-info {
    width: 45%;
  }
  .category-img {
    width: 55%;
  }
  .catalog-category {
    min-height: 598px;
  }
  .processing-img {
    display: none;
  }
  .production-view .swiper-slide {
    margin-top: 0;
  }
  .production-company {
    margin: 0;
  }
  .catalog-toolbar {
    padding: 75px 0 75px 0;
    width: 100%;
    display: flex;
    justify-content: center;
  }
  .catalog-toolbar-wrap {
    //width: auto;
    display: flex;
    justify-content: center;
  }
  .filter-item h3:after {
    top: 5px;
    font-size: 9px;
  }
  .catalog-toolbar-title h1 {
    font-size: 30px;
  }
  .filter-item h3 {
    font-size: 20px;
    padding: 0 30px 0 0;
  }
  .filter-item ol li label span {
    font-size: 16px;
  }
  .filter-item ol li label span:before {
    top: 3px;
  }
  .filter-item ol li label span:after {
    top: 4px;
  }

}

@media screen and (max-width: 825px) {
  .contacts {
    width: 100%;
  }
  .map-wrap {
    width: 100%;
    height: 350px;
  }
  .contacts-info-list {
    display: flex;
    flex-wrap: wrap;
  }
  .contacts-info {
    padding: 9px 15px 9px 0;
    min-width: calc(100% / 3);
  }
  .contacts {
    padding: 35px 35px 15px 35px;
  }
  .contacts-bottom {
    padding: 25px 0 15px 0;
  }
  .category-view {
    margin: 0;
  }
  .category-title {
    padding: 0;
    max-width: 375px;
  }
  .category-info {
    width: 100%;
    padding: 0 0 88px 0;
  }
  .category-img {
    width: 0;
    position: static;
  }
  .catalog-category {
    min-height: 500px;
    position: relative;
  }
  .category-img-wrap {
    right: 0;
    left: auto;
    max-width: calc(100% - 265px);
  }
  .category-view-control .slider-control {
    width: 100%;
    max-width: 264px;
  }
  .category-view-control {
    padding: 0 0 25px 0;
  }
}

@media screen and (max-width: 760px) {
  .item-slider-wrap {
    width: 100%;
  }
  .chosen-product {
    margin: 0;
    background-color: transparent;
  }
  .item-slider-wrap .section-pagination, .item-slider-wrap .section-arrows {
    background-color: #f7f6f2;
  }
  .product-info:first-child:after {
    display: none;
  }
  .product-info {
    padding: 0 15px 20px 0;
    min-width: 50%;
  }
  .product-price {
    padding: 0;
  }
  .product-data {
    width: 100%;
    padding-bottom: 0;
    display: flex;
    flex-wrap: wrap;
  }
  .error-type p {
    font-size: 130px;
  }
  .error-type h2 {
    font-size: 106px;
    margin-top: -15.5%;
  }
  .page-error-btn {
    padding: 30px 0 0 0;
  }
  .not-found-wrap {
    padding: 25px 0 50px 0;
  }
  .equipment-info .bg-text {
    display: none;
  }
}

@media screen and (max-width: 685px) {
  .goods-item {
    width: calc((100% / 2) - 1px);
    padding-bottom: 55.9%;
  }
  .error-type p {
    font-size: 96px;
  }
  .error-type h2 {
    font-size: 81px;
    margin-top: -13.5%;
  }
  .catalog-category {
    min-height: 358px;
  }
  .main-title h1 {
    font-size: 35px;
  }
  .main-section-img span {
    top: 0;
    right: 0;
    background-position: 50% 0;
  }
  .see-products {
    padding: 15px 0;
  }
  .main-section-text {
    width: 75.4%;
  }
  .main-section-img {
    width: calc(100% - 75.4%);
  }
  .our-mission-text {
    padding: 60px 34px 47px 45px;
  }
  .production-info {
    width: 100%;
    padding: 0 15px 26px 0;
  }
  .production-info-slider {
    width: 100%;
  }
  .production-view .swiper-slide {
    height: 400px;
  }
  .materials-wrap {
    width: 100%;
  }
  .materials-bg {
    width: 100%;
    height: 350px;

  }
  .materials-bg span {
    left: -15px;
  }
  .prices-bg {
    left: 85%;
  }
  .about-info-bg {
    width: 100%;
    height: 350px;
    order: 1;
  }
  .about-info-bg span {
    right: -15px;
    left: -15px;
    bottom: 0;
  }
  .about-info {
    width: 100%;
    order: 2;
  }
  .processing-item.w-33 {
    width: calc(100% / 2);
    padding: 15px;
  }
  .principles-item {
    width: 100%;
    margin: 0 0 1px 0;
  }
  .principles-list {
    margin: 0;
  }
  .production-info-slider .section-arrows {
    display: none;
  }
  .section-bullets {
    width: 100%;
    padding: 0 20px;
  }
  .production-info-slider {
    padding: 12px 0 36px 0;
  }
  .about-info {
    padding: 40px 0 0 0;
  }
  .our-mission-text {
    padding: 24px 0 24px 15px;
  }
  .min-eq-number p {
    font-size: 86px;
    line-height: 1;
  }
  .min-eq-text p {
    font-size: 17px;
  }
  .processing-wrap {
    padding: 40px 0 30px 0;
  }
  .production-info-wrap {
    padding: 24px 0 17px 0;
  }
  .page-nav ol li {
    padding: 11px 10px;
  }
  .page-nav ol li a {
    font-size: 20px;
  }
  .success-info {
    width: 100%;
    min-height: calc(100vh - 75px);
  }
  .success-item {
    position: static;
  }
  .form-sended {
    margin: 0;
    position: relative;
  }
  .success-info {
    padding: 0;
  }
  .centered {
    padding: 30px 0;
  }
  .success-img {
    max-width: 181px;
    top: 40%;
    right: 0;
    transform: translateX(0);
    background-size: contain;
    background-position: bottom center;
  }
  .category-img-wrap a {
    top: 63%;
    left: 60%;
  }
  .category-img-wrap a span {
    width: 65px;
    height: 65px;
  }
  .category-title p {
    display: none;
  }
}

@media screen and (max-width: 465px) {
  .goods-list-wrap {
    margin: 0;
  }
  .goods-item {
    width: 100%;
    padding-bottom: 100%;
    margin: 0 0 1px 0;
  }
  .goods-item a span {
    max-width: 120px;
    font-size: 15px;
  }
  .contacts h4,
  .page-error h1,
  .goods-list-title h2,
  .category-title h3,
  .section-title h3,
  .equipment-renew-text h4,
  .info-title h1 {
    font-size: 26px;
  }
  .info-text p {
    margin: 0 0 16px 0;
    font-size: 13px;
  }
  .info-content {
    padding: 30px 0;
  }
  .chosen-product {
    padding: 0 0 20px 0;
  }
  .goods-list {
    padding: 19px 0;
  }
  .catalog-btn, .modal-close, .go-back {
    padding: 10px;
    font-size: 18px;
  }
  .send-order span.send-btn {
    font-size: 18px;
  }
  .send-order {
    padding: 10px;
  }
  .error-type p {
    font-size: 60px;
  }
  .error-type h2 {
    font-size: 65px;
    margin-top: -14.5%;
  }
  .page-error-btn a {
    width: 100%;
  }
  .category-img-wrap {
    max-width: 100%;
    padding-top: 56px;
  }
  .category-view-control {
    padding: 0 0 15px 0;
    position: static;
  }
  .category-view-control .slider-control {
    max-width: 100%;
  }
  .equipment-renew-text {
    width: 100%;
  }
  .equipment-renew-img {
    display: none;
  }
  .main-section-text {
    padding: 0;
  }
  .main-title h1 {
    font-size: 23px;
  }
  .see-products p {
    font-size: 13px;
  }
  .see-products a.main-btn {
    width: 100%;
  }
  .about-info-bg {
    height: 280px;
  }
  .our-mission-text h4 {
    font-size: 16px;
  }
  .processing-item.w-33 {
    width: 100%;
    padding: 8px 15px;
  }
  .min-eq-number {
    width: 100%;
  }
  .min-eq-number p {
    text-align: center;
  }
  .min-eq-text {
    width: 100%;
  }
  .min-eq-text p {
    font-size: 16px;
    text-align: center;
  }
  .production-view .swiper-slide {
    height: 300px;
  }
  .field-item {
    width: 100%;
  }
  .page-nav ol li a {
    font-size: 18px;
  }
  .catalog-toolbar-title h1 {
    font-size: 26px;
  }
  .filter-item h3 {
    font-size: 18px;
  }
  .success-back .main-btn {
    width: 100%;
    height: 60px;
  }
  .success-item {
    display: none;
  }
  .category-title p {
    font-size: 50px;
  }
  .category-img-wrap a span {
    width: 50px;
    height: 50px;
  }
  .category-img-wrap a {
    top: auto;
    bottom: 8px;
    left: auto;
    right: 8px;
  }
  .category-img-wrap a span:before {
    top: -4px;
    left: -4px;
    right: -4px;
    bottom: -4px;
  }
  .category-img-wrap a span i {
    font-size: 14px;
  }
}
